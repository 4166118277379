var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white px-3",attrs:{"id":"course-content"}},[_c('div',{staticClass:"pb-1.5 pt-3",on:{"click":function($event){return _vm.$router.push({
      query: {
        page: 'course-material'
      }
    })}}},[_c('div',{staticClass:"inline-block font-semibold text-xl"},[_vm._v("Material del Curso")]),_c('img',{staticClass:"float-right mt-1",attrs:{"src":require("@/assets/icons/Chevron_right.svg")}})]),_c('div',{staticClass:"py-1.5",on:{"click":function($event){return _vm.$router.push({
      query: {
        page: 'homework'
      }
    })}}},[_c('div',{staticClass:"inline-block font-semibold text-xl"},[_vm._v("Tareas")]),_c('img',{staticClass:"float-right mt-1",attrs:{"src":require("@/assets/icons/Chevron_right.svg")}})]),_c('div',{staticClass:"py-1.5",on:{"click":function($event){return _vm.$router.push({
      query: {
        page: 'exams'
      }
    })}}},[_c('div',{staticClass:"inline-block font-semibold text-xl"},[_vm._v("Exámenes")]),_c('img',{staticClass:"float-right mt-1",attrs:{"src":require("@/assets/icons/Chevron_right.svg")}})]),_c('div',{staticClass:"font-semibold mt-1.5 text-lg"},[_vm._v("Groups")]),_c('div',{staticClass:"my-1.5 text-gray-400",on:{"click":function($event){return _vm.openChat(_vm.activeCourse.id + '-group_' + 'general')}}},[_c('span',{staticClass:"pr-2"},[_vm._v("#")]),_c('span',{},[_vm._v("General")])]),_c('div',{staticClass:"font-semibold mt-3 text-lg"},[_vm._v("Direct Messages")]),_vm._l((_vm.courseMembers),function(member){return _c('div',{key:member.uid,staticClass:"my-1.5 text-gray-400",on:{"click":function($event){_vm.openChat(_vm.activeCourse.id + '-' + _vm.sortStrings(_vm.loggedInUser.uid, member.uid))}}},[_vm._m(0,true),_c('div',{staticClass:"inline-block ml-1 mt-0.5"},[_vm._v(_vm._s(member.displayName))])])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-top bg-gray-100 border border-gray-300 h-4 inline-block pr-2 relative rounded-xl text-center w-4"},[_c('img',{staticClass:"user",attrs:{"src":require("@/assets/icons/User.svg")}}),_c('div',{staticClass:"status-indicator absolute bg-green-500 border-1 border-white rounded-full"})])}]

export { render, staticRenderFns }