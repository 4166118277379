<template>
  <div id="exams-page"
    class="page bg-white fixed h-screen top-0 w-screen z-50"
    v-bind:class="{
      open: open
    }">

    <div class="border-b border-gray-200 h-7 relative w-screen">
      <div class="inline-block left-0 p-2 md:hidden"
        v-on:click="close()">
        <img class="mt-0.5" src="@/assets/icons/Back.svg" />
      </div>

      <div class="align-top inline-block leadind-none mt-1 px-2 text-2xl">Exams</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  computed: {
    texts () {
      return this.$store.state.UI.selectedLanguage
    },
    open () {
      return this.$route.query.page === 'exams'
    }
  },
  methods: {
    close () {
      this.$router.push({
        query: {}
      })
    }
  }
}
</script>
