<template>
  <div class="fixed pt-safe top-0">
    <div id="topbar" class="border-b border-gray-200 flex h-7 text-white w-screen md:text-black"
      v-bind:style="{
        backgroundColor: activeCourse.default_color
      }">
      <img class="flex-none px-2.5 py-2.5 md:hidden"
        src="@/assets/icons/Menu_white.svg"
        v-on:click="$router.push({query: { sidebar: 'open' }})"/>

      <div class="align-top flex-grow mt-1 md:pl-1.5">
        <div class="font-bold leading-none mb-0.5 text-xl">{{activeCourse.title}}</div>
        <div class="leading-none text-gray-300 text-xs md:text-gray-400">Group {{activeCourse.group}}</div>
      </div>

      <img class="flex-none px-2.5 py-2.5 md:hidden"
        src="@/assets/icons/Info_white.svg"/>

      <img class="flex-none hidden px-2.5 py-2.5 md:inline"
        src="@/assets/icons/Info.svg"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeCourse: Object
  }
}
</script>

<style lang="sass" scoped>
@media (min-width: 768px)
  #topbar
    background-color: #FFF !important
    width: 300px
    @apply border-r
</style>
