<template>
  <div class="border-r border-gray-200 h-screen"
    id="course">
    <div id="topbar-statusbarcover"
      class="fixed top-0 w-screen"
      style="z-index: 30"
      v-bind:style="{
        backgroundColor: activeCourse.default_color
      }"
      v-if="activeCourse"></div>
    <TopBar :activeCourse="activeCourse" v-if="activeCourse" />
    <CourseContent />

    <CourseMaterial :activeCourse="activeCourse" />
    <Homework :activeCourse="activeCourse" />
    <Exams :activeCourse="activeCourse" />
  </div>
</template>

<script>
import { db } from '@/firebase/app'
import CourseContent from '@/components/Course/Content'
import CourseMaterial from '@/views/Course/Material'
import Exams from '@/views/Course/Exams'
import Homework from '@/views/Course/Homework'
import TopBar from '@/components/Course/TopBar'

import {
  Plugins,
  StatusBarStyle
} from '@capacitor/core'

const { StatusBar } = Plugins

export default {
  components: {
    CourseContent,
    CourseMaterial,
    Exams,
    Homework,
    TopBar
  },
  computed: {
    activeCourse () {
      const courses = this.$store.state.Auth.courses
      if (courses === undefined) {
        return null
      }
      return courses.filter(course => course.id === this.$route.params.course_id)[0]
    }
  },
  methods: {
    loadMembers () {
      const members = []
      for (let i = 0; i < this.activeCourse.members.length; i++) {
        const memberID = this.activeCourse.members[i]

        db.collection('users').doc(memberID).get().then((doc) => {
          members.push(doc.data())
          if (members.length === this.activeCourse.members.length) {
            this.$store.commit('Courses/setMembers', members)
          }
        })
      }
    }
  },
  mounted () {
    if (this.activeCourse) {
      this.loadMembers()
    }
    StatusBar.setStyle({
      style: StatusBarStyle.Dark
    })
  },
  watch: {
    activeCourse (newVal, oldVal) {
      if (newVal === oldVal) {
        return false
      }

      if (newVal === undefined) {
        this.$store.commit('Courses/setMembers', [])
        return false
      }

      this.loadMembers()

      return true
    }
  }
}
</script>

<style lang="sass" scoped>
#course
  padding-top: 56px
  padding-top: calc(env(safe-area-inset-top) + 56px)

#topbar-statusbarcover
  height: 0
  height: env(safe-area-inset-top)

@media (min-width: 768px)
  #course
    margin-left: 80px
    max-width: 300px
</style>
