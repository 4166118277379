<template>
  <div id="course-material-page"
    class="page bg-white fixed h-screen w-full z-70"
    v-bind:class="{
      open: open
    }">

    <div class="border-b border-gray-200 flex h-7 relative w-full">
      <div class="flex-none p-1.5 w-10 md:hidden"
        v-on:click="close()"
        v-if="activeCourse">
        <img class="" src="@/assets/icons/LeftArrow.svg" />
        <div class="overflow-ellipsis overflow-hidden text-xs">{{activeCourse.title}}</div>
      </div>

      <div class="align-top flex-grow font-bold leading-none px-2 py-2 text-center text-xl">{{texts.courseMaterial.title}}</div>

      <div class="flex-none p-1.5 w-10" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  computed: {
    texts () {
      return this.$store.state.UI.selectedLanguage
    },
    open () {
      return this.$route.query.page === 'course-material'
    }
  },
  methods: {
    close () {
      this.$router.push({
        query: {}
      })
    }
  },
  props: {
    activeCourse: Object
  }
}
</script>
