<template>
  <div id="course-content"
    class="bg-white px-3">
    <div class="pb-1.5 pt-3"
      v-on:click="$router.push({
        query: {
          page: 'course-material'
        }
      })">
      <div class="inline-block font-semibold text-xl">Material del Curso</div>
      <img class="float-right mt-1" src="@/assets/icons/Chevron_right.svg" />
    </div>

    <div class="py-1.5"
      v-on:click="$router.push({
        query: {
          page: 'homework'
        }
      })">
      <div class="inline-block font-semibold text-xl">Tareas</div>
      <img class="float-right mt-1" src="@/assets/icons/Chevron_right.svg" />
    </div>

    <div class="py-1.5"
      v-on:click="$router.push({
        query: {
          page: 'exams'
        }
      })">
      <div class="inline-block font-semibold text-xl">Exámenes</div>
      <img class="float-right mt-1" src="@/assets/icons/Chevron_right.svg" />
    </div>

    <div class="font-semibold mt-1.5 text-lg">Groups</div>
    <div class="my-1.5 text-gray-400" v-on:click="openChat(activeCourse.id + '-group_' + 'general')">
      <span class="pr-2">#</span>
      <span class="">General</span>
    </div>

    <div class="font-semibold mt-3 text-lg">Direct Messages</div>
    <div class="my-1.5 text-gray-400"
      v-on:click="openChat(activeCourse.id + '-' + sortStrings(loggedInUser.uid, member.uid))"
      v-for="member in courseMembers"
      v-bind:key="member.uid">
      <div class="align-top bg-gray-100 border border-gray-300 h-4 inline-block pr-2 relative rounded-xl text-center w-4">
        <img class="user" src="@/assets/icons/User.svg" />
        <div class="status-indicator absolute bg-green-500 border-1 border-white rounded-full">
        </div>
      </div>
      <div class="inline-block ml-1 mt-0.5">{{member.displayName}}</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeCourse () {
      const courses = this.$store.state.Auth.courses
      if (courses === undefined) {
        return null
      }
      return courses.filter(course => course.id === this.$route.params.course_id)[0]
    },
    courseMembers () {
      return this.$store.state.Courses.members
    },
    loggedInUser () {
      return this.$store.state.Auth.user
    }
  },
  methods: {
    sortStrings (a, b) {
      if (a > b) {
        return a + '_and_' + b
      } else {
        return b + '_and_' + a
      }
    },
    openChat (chat) {
      this.$router.push({
        query: {
          chat: chat
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
#course-content
  min-height: calc(100vh - 56px)
  min-height: calc(100vh - env(safe-area-inset-top) - 56px)

img.user
  margin: 10px 11px 11px

.status-indicator
  bottom: -1px
  height: 10px
  right: -1px
  width: 10px
</style>
